import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { JssThemeProvider } from '@bb-ui/react-library/dist/components/JssThemeProvider';
import { JssThemeProviderProps } from '@bb-ui/react-library/dist/components/JssThemeProvider/JssThemeProvider';

export interface I18nThemeProvider extends Omit<JssThemeProviderProps, 'isRtl'> {
}

export const I18nThemeProvider: React.FunctionComponent<I18nThemeProvider> = (props) => {
  const { children, theme, ...other } = props;
  const { i18n } = useTranslation();

  return (
    <JssThemeProvider isRtl={i18n.dir() === 'rtl'} theme={theme} {...other}>
      <Helmet
        htmlAttributes={{ lang: i18n.language }}
        bodyAttributes={{ dir: i18n.dir() }}
      />
      {children}
    </JssThemeProvider>
  );
};

export default I18nThemeProvider;
