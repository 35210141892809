import * as React from 'react';
import { default as MuiSvgIcon } from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { SvgIconProps } from './SvgIcon.types';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  strokeIcon: {
    fill: 'none',
  },
  fillColor: {
    '--icon-fill-color': (props: SvgIconProps) => props.fillColor,
  },
}));


export const SvgIcon = React.forwardRef((
  props: SvgIconProps,
  ref?: React.Ref<SVGSVGElement>,
) => {
  const {
    fillColor,
    fontSize,
    titleAccess,
    viewBox,
    className,
    isColor,
    classes: classesProp,
    ...other
  } = props;

  const classes = useStyles(props);
  let viewBoxValue = '0 0 24 24';

  const classNames = classnames(
    !isColor && classes.strokeIcon,
    fillColor && classes.fillColor,
    className,
  );

  if (fontSize === 'small') {
    viewBoxValue = '0 0 16 16';
  } else if (fontSize === 'large') {
    viewBoxValue = '0 0 38 38';
  }

  viewBoxValue = viewBox || viewBoxValue;

  return (
    <MuiSvgIcon
      className={classNames}
      viewBox={viewBoxValue}
      fontSize={fontSize}
      classes={classesProp}
      ref={ref}
      {...other}
      aria-hidden={titleAccess ? undefined : true}
      role={titleAccess ? 'img' : 'presentation'}
      aria-label={titleAccess}
    />);
});

export default SvgIcon;
