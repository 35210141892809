import * as React from 'react';
import { Chip } from '../Chip';
import { ChipProps } from '../Chip.types';

export const OutlineChip = React.forwardRef((
  props: ChipProps,
  ref: React.Ref<HTMLDivElement>,
) => (
  <Chip variant="outlined" {...props} ref={ref} />
));

export default OutlineChip;
