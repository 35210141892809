import * as React from 'react';
import { default as MuiStep, StepProps } from '@material-ui/core/Step';

export const Step = React.forwardRef((
  props: StepProps,
  ref: React.Ref<unknown>,
) => (
  <MuiStep ref={ref} {...props} />
));

export default Step;
