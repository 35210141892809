import * as React from 'react';
import { createSvgIcon } from '..';

export const Exclamation = createSvgIcon(
  <>
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15l-.5-10h1L12 15z" />
    <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M12.75 18.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
  </>,
  'default',
);
