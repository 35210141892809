import * as React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon';

export default function createSvgIcon(path: JSX.Element, size?: 'small' | 'inherit' | 'default' | 'large' | undefined) {
  return React.forwardRef((props: SvgIconProps, ref?: React.Ref<SVGSVGElement>) => {
    // exclude ref from props being passed to component to avoid collision with the ref type from forwardRef
    const { ref: refProp, ...other } = props;

    return (
      <SvgIcon fontSize={size} ref={ref} {...other}>
        {path}
      </SvgIcon>
    );
  });
}
