import * as React from 'react';
import { default as MuiTableHead, TableHeadProps } from '@material-ui/core/TableHead';

export const TableHead = React.forwardRef((
  props: TableHeadProps,
  ref: React.Ref<HTMLTableSectionElement>,
) => (
  <MuiTableHead ref={ref} {...props} />
));

export default TableHead;
