// Converts px units in JSS to rems instead. Use keep-px as a unit if you
// absolutely need to specify px.

type Style = { [key: string]: any };

export default function setupPxToRem(): {} {
  function onProcessStyle(style: Style) {
    const basePxSize = 16;
    const findPx = /(\d+)px/g;
    const findKeepPx = /(\d+)keep-px\b/g;
    const exclude = /^(border|border-width|box-shadow|-webkit-box-shadow|outline|stroke-dasharray)$/;

    function px2rem(style: Style): Style {
      const result = { ...style };

      Object.keys(result).forEach(key => {
        if (exclude.test(key)) {
          return;
        }

        if (typeof result[key] === 'string') {
          // Note /g flags in regexps above.

          result[key] = result[key].replace(findPx, (match: string, px: string) => `${parseInt(px, 10) / basePxSize}rem`);
          result[key] = result[key].replace(findKeepPx, '$1px');
        } else if (typeof result[key] === 'object') {
          result[key] = px2rem(result[key]);
        }
      });

      return result;
    }

    return px2rem(style);
  }

  return { onProcessStyle };
}
