import * as React from 'react';
import { Button } from '../Button';
import { IButtonProps } from '../Button.types';

export const PrimaryButton = React.forwardRef((
  props: IButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) => (
  <Button variant="contained" color="primary" {...props} ref={ref} />
));

export default PrimaryButton;
