import { default as React, FunctionComponent, useState, useRef } from 'react';
import { Key } from 'ts-key-enum';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { TextField } from '../TextField';
import { InputAdornment } from '../InputAdornment';
import { Search } from '../../internal/icons/small/Search';
import { Clear } from '../../internal/icons/small/Clear';
import { IconButton } from '../IconButton';
import { ISearchBoxProps } from './SearchBox.types';

export const styles = (theme: Theme) => createStyles({
  input: {
    '&$adornedEnd': {
      paddingRight: 0,
    },
  },
  adornedEnd: {
  },
  label: {
    transform: 'translate(40px, 9px) scale(0.875)',
  },
});

export const BbSearchBox: FunctionComponent<ISearchBoxProps & WithStyles<typeof styles>> = (props) => {
  const { clearButtonAriaLabel, classes, className, disabled, inputId, inputRef, InputLabelProps, InputProps, label, onChange, onClear, onEscape, onKeyDown, onSearch, TextFieldProps, value } = props;

  // For an uncontrolled component, we will store the value here
  const [inputValue, setInputValue] = useState('');

  const getValue = () => (value === undefined ? inputValue : value);

  const [isLabelShrunk, setIsLabelShrunk] = useState(!!getValue());

  const ownRef = useRef(null);

  const inputEl: React.MutableRefObject<HTMLInputElement | null> = inputRef || ownRef;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Only need to update uncontrolled value if a controlled value is not passed in
    if (value === undefined) {
      setInputValue(event.target.value);
    }
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const onClearInternal = (event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (onClear) {
      onClear(event as React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>);
    }
    if (!event.defaultPrevented) {
      if (onChange) {
        onChange('');
      }
      if (value === undefined) {
        setInputValue('');
      }

      if (inputEl.current) {
        inputEl.current.focus();
      }
    }
  };

  const onKeyDownInternal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case Key.Escape:
        if (onEscape) {
          onEscape(event);
        }
        if (!event.defaultPrevented) {
          onClearInternal(event);
        }
        event.stopPropagation();
        break;
      case Key.Enter:
        if (onSearch) {
          onSearch(getValue());
        }
        break;
      default:
        if (onKeyDown) {
          onKeyDown(event);
        }
        break;
    }
  };

  const onFocus = () => {
    if (!isLabelShrunk) {
      setIsLabelShrunk(true);
    }
  };

  const onBlur = () => {
    if (isLabelShrunk && getValue() === '') {
      setIsLabelShrunk(false);
    }
  };

  return (
    <TextField
      className={className}
      disabled={disabled}
      floatingLabel
      fullWidth
      label={label || 'Search'}
      id={inputId}
      inputRef={inputEl}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChangeInternal}
      onKeyDown={onKeyDownInternal}
      value={getValue()}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
        shrink: isLabelShrunk,
        ...InputLabelProps,
      }}
      InputProps={{
        classes: {
          root: classes.input,
          adornedEnd: classes.adornedEnd,
        },
        startAdornment: isLabelShrunk ? null : (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: getValue() && getValue().length > 0 ? (
          <InputAdornment
            position="end"
          >
            <IconButton
              aria-label={clearButtonAriaLabel || 'Clear search input'}
              onClick={onClearInternal}
              onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
                if (event.key === Key.Enter) {
                  event.stopPropagation();
                  onClearInternal(event);
                }
              }}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : null,
        ...InputProps,
      }}
      {...TextFieldProps}
    />
  );
};

export default withStyles(styles)(BbSearchBox);
