import * as React from 'react';
import { default as MuiRadioGroup, RadioGroupProps } from '@material-ui/core/RadioGroup';

export const RadioGroup = React.forwardRef((
  props: RadioGroupProps,
  ref: React.Ref<unknown>,
) => (
  <MuiRadioGroup ref={ref} {...props} />
));

export default RadioGroup;
