import * as React from 'react';
import { default as MuiPopover, PopoverProps } from '@material-ui/core/Popover';

export const Popover = React.forwardRef((
  props: PopoverProps,
  ref: React.Ref<unknown>,
) => (
  <MuiPopover ref={ref} {...props} />
));

export default Popover;
