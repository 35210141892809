import * as React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { default as MuiLinearProgress, LinearProgressProps } from '@material-ui/core/LinearProgress';
import { getColor } from '../../styles';

export interface ILinearProgressProps extends LinearProgressProps {
  error?: boolean;
}

export const linearProgressStyles = (theme: Theme) => {
  const errorBackground = theme.palette.type === 'light' ? theme.palette.error.light : theme.palette.error.dark;
  return createStyles({
    colorComplete: {
      backgroundColor: getColor('success.main', theme),
    },
    colorCompleteBar: {
      backgroundColor: getColor('success.main', theme),
    },
    colorError: {
      backgroundColor: errorBackground,
    },
    colorErrorBar: {
      backgroundColor: getColor('error.main', theme),
    },
  });
};

const colorPrimaryStateColor = (props: ILinearProgressProps & WithStyles<typeof linearProgressStyles>) => {
  const { classes, error, value } = props;
  if (error) {
    return classes.colorError;
  }
  if (value === 100) {
    return classes.colorComplete;
  }
  return undefined;
};

const barColorPrimaryStateColor = (props: ILinearProgressProps & WithStyles<typeof linearProgressStyles>) => {
  const { classes, error, value } = props;
  if (error) {
    return classes.colorErrorBar;
  }
  if (value === 100) {
    return classes.colorCompleteBar;
  }
  return undefined;
};

export const LinearProgress = React.forwardRef((
  props: ILinearProgressProps & WithStyles<typeof linearProgressStyles>,
  ref: React.Ref<unknown>,
) => {
  const { classes, error, value, ...other } = props;
  const colorPrimary = colorPrimaryStateColor(props);
  const barColorPrimary = barColorPrimaryStateColor(props);
  return (
    <MuiLinearProgress ref={ref} classes={{ colorPrimary, barColorPrimary }} value={value} {...other} />
  );
});

export default withStyles(linearProgressStyles)(LinearProgress);
