import * as React from 'react';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiCircularProgress, CircularProgressProps } from '@material-ui/core/CircularProgress';
import { TypographyProps } from '@material-ui/core/Typography';
import { getColor } from '../../styles';
import { Typography } from '../../Typography';
import { Check } from '../../../internal/icons/small/Check';
import { Exclamation } from '../../../internal/icons/medium/Exclamation';

export interface ICircularProgressProps extends CircularProgressProps {
  error?: boolean;
  formatPercentage?: (value: number | undefined) => string;
  label?: string;
  labelProps?: TypographyProps;
  showPercent?: boolean;
  success?: boolean;
}

enum CircularProgressSize {
  small = 24,
  medium = 38,
  large = 50
}

enum CircularProgressVariant {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
  static = 'static'
}

const styles = (theme: Theme) => {
  const progressBackground = theme.palette.type === 'light' ? getColor('brand.light', theme) : getColor('brand.dark', theme);

  return createStyles({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    secondaryRoot: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    largeLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeLarge,
      fontWeight: theme.typography.fontWeightSemiBold,
      paddingTop: '12px',
    },
    smallLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeDefault,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontStyle: 'italic',
      paddingLeft: '8px',
    },
    circle: {
      color: progressBackground,
      position: 'absolute',
    },
    circleIndeterminate: {
      color: getColor('brand.main', theme),
      position: 'relative',
      left: 0,
    },
    circleActive: {
      color: getColor('brand.main', theme),
      position: 'relative',
      left: 0,
    },
    errorState: {
      color: theme.palette.error.main,
      position: 'relative',
      left: 0,
    },
    successState: {
      color: theme.palette.success.main,
      position: 'relative',
      left: 0,
    },
    progressValue: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    valueText: {
      fontSize: '10px',
      fontWeight: theme.typography.fontWeightBold,
    },
    valueWrapper: {
      position: 'relative',
      display: 'inline-flex',
    },
  });
};

const getSize = (size: string | number) => {
  switch (size) {
    case 'small':
      return CircularProgressSize.small;
    case 'medium':
      return CircularProgressSize.medium;
    case 'large':
      return CircularProgressSize.large;
    default:
      return size;
  }
};

export const CircularProgress = React.forwardRef((
  props: ICircularProgressProps & WithStyles<typeof styles>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const {
    classes,
    error = false,
    formatPercentage = (value: number) => `${value}%`,
    label,
    labelProps,
    showPercent = false,
    size = CircularProgressSize.large,
    success = false,
    value,
    variant = CircularProgressVariant.indeterminate,
    ...other
  } = props;
  const spinnerSize = getSize(size);
  const sanitizedValue = value ? Math.min(100, Math.max(0, value || 0)) : value;
  const DEFAULT_THICKNESS = 4;

  const backgroundCircle = (
    <MuiCircularProgress
      variant={CircularProgressVariant.static}
      value={100}
      className={classes.circle}
      size={spinnerSize}
      thickness={DEFAULT_THICKNESS}
      aria-hidden="true"
      aria-valuenow={undefined}
      role="presentation"
    />
  );

  const renderProgressOrIcons = () => {
    if (error || success) {
      return (
        <div className={classes.valueWrapper}>
          <MuiCircularProgress
            thickness={DEFAULT_THICKNESS}
            size={spinnerSize}
            className={error ? classes.errorState : classes.successState}
            variant={CircularProgressVariant.static}
            value={100}
          />
          { spinnerSize >= CircularProgressSize.medium ? (
            <div className={classes.progressValue}>
              { error ? <Exclamation /> : <Check /> }
            </div>
          ) : null }
        </div>
      );
    }

    if (showPercent && sanitizedValue !== undefined) {
      return (
        <div className={classes.valueWrapper}>
          { backgroundCircle }
          <MuiCircularProgress
            variant={variant}
            className={classes.circleActive}
            size={spinnerSize}
            thickness={DEFAULT_THICKNESS}
            aria-hidden={label ? true : undefined}
            role={label ? 'presentation' : undefined}
            ref={ref}
            value={sanitizedValue}
          />
          { spinnerSize >= CircularProgressSize.medium ? (
            <div className={classes.progressValue}>
              <Typography className={classes.valueText}>{ formatPercentage(sanitizedValue) }</Typography>
            </div>
          ) : null }
        </div>
      );
    }

    return (
      <>
        { backgroundCircle }
        <MuiCircularProgress
          variant={variant}
          className={classes.circleIndeterminate}
          size={spinnerSize}
          thickness={DEFAULT_THICKNESS}
          aria-hidden={label ? true : undefined}
          role={label ? 'presentation' : undefined}
          ref={ref}
          value={sanitizedValue}
          {...other}
        />
      </>
    );
  };

  return (
    <div className={spinnerSize > CircularProgressSize.small ? classes.root : classes.secondaryRoot} role="status">
      { renderProgressOrIcons() }
      { label ? (
        <Typography
          component="p"
          variant={spinnerSize > CircularProgressSize.small ? 'h4' : 'h5'}
          className={spinnerSize > CircularProgressSize.small ? classes.largeLabel : classes.smallLabel}
          {...labelProps}
        >
          {label}
        </Typography>
      ) : null }
    </div>
  );
});

export default withStyles(styles)(CircularProgress);
