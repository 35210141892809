import * as React from 'react';
import classnames from 'classnames';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@material-ui/core/DialogTitle';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { Close } from '../../internal/icons/medium/Close';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

export interface DialogTitleProps extends MuiDialogTitleProps {
  hideCloseButton?: boolean;
  closeLabel?: string;
  onClose?: () => void;
  closeButtonProps?: IconButtonProps;
}

export const styles = (theme: Theme) => createStyles({
  closeButton: {
    position: 'relative',
    right: '-6px',
  },
});

export const DialogTitle = React.forwardRef((
  props: DialogTitleProps & WithStyles<typeof styles>,
  ref: React.Ref<unknown>,
) => {
  const {
    classes,
    disableTypography,
    children,
    hideCloseButton,
    id,
    closeLabel,
    closeButtonProps,
    onClose,
    className,
    ...other
  } = props;
  const combinedClassName = classnames(className, 'bb-dialog-title');

  return (
    <MuiDialogTitle className={combinedClassName} disableTypography ref={ref} {...other}>
      {disableTypography && children}
      {!disableTypography &&
        <Typography variant="h1" id={id} tabIndex={-1} className="bb-h1-element">{children}</Typography>
      }
      {!hideCloseButton &&
        <IconButton
          className={classes.closeButton}
          aria-label={closeLabel}
          onClick={onClose}
          {...closeButtonProps}
        >
          <Close />
        </IconButton>
      }
    </MuiDialogTitle>
  );
});

DialogTitle.defaultProps = {
  closeLabel: 'Close',
};

export default withStyles(styles)(DialogTitle);
