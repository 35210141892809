import * as React from 'react';
import { default as MuiToolbar, ToolbarProps } from '@material-ui/core/Toolbar';

export const Toolbar = React.forwardRef((
  props: ToolbarProps,
  ref: React.Ref<HTMLDivElement>,
) => (
  <MuiToolbar ref={ref} {...props} />
));

export default Toolbar;
