import * as React from 'react';
import { default as MuiTabs } from '@material-ui/core/Tabs';
import { ITabsProps } from './Tabs.types';

export const Tabs: React.FunctionComponent<ITabsProps> = ((
  props: ITabsProps,
) => {
  const { ariaOrientation, textColor, ...other } = props;

  return (
    <MuiTabs
      textColor={textColor || 'primary'}
      aria-orientation={ariaOrientation}
      {...other}
    />
  );
});

Tabs.defaultProps = {
  ariaOrientation: 'horizontal',
};

export default Tabs;
