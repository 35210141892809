import * as React from 'react';
import { create, Jss, StyleSheet } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import jssRtl from 'jss-rtl';
import { BbThemeProvider } from '../BbThemeProvider';
import pxToRem from '../../internal/utilities/px-to-rem';

const generateClassName = createGenerateClassName();

type JssThemeProviderProps = {
  jssInsertionPoint?: string;
  theme?: string;
  isRtl?: boolean;
  children: React.ReactNode;
};

function jssThemeProvider(Component: React.ComponentType) {
  console.warn('jssThemeProvider HOC has been deprecated and will be removed in a future release. Use JssThemeProvider instead.');
  const JssThemeProvider = (props: JssThemeProviderProps) => {
    const { theme, jssInsertionPoint, isRtl } = props;
    const jss: Jss = create({
      plugins: [...jssPreset().plugins, pxToRem(), jssRtl()],
      insertionPoint: jssInsertionPoint || '',
    });

    return (
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <BbThemeProvider theme={theme} isRtl={isRtl}>
          <CssBaseline />
          <Component {...props} />
        </BbThemeProvider>
      </StylesProvider>
    );
  };

  return JssThemeProvider;
}

export default jssThemeProvider;
