import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiTab } from '@material-ui/core/Tab';
import classnames from 'classnames';
import { ITabProps, TabVariant } from './Tab.types';

export const styles = (theme: Theme) => createStyles({
  fullWidth: {
    flex: 1,
    minWidth: 0,
  },
  nonFullWidth: {
    // Do not wrap text in a Tab - used when tabs are not full width
    maxWidth: '10000px', // Want the max to be infinite, tried undefined and didn't work
  },
  global: {
    padding: '16px',
    fontSize: theme.typography.fontSizeLarge,
  },
  section: {
    padding: '12px',
    fontSize: theme.typography.fontSizeDefault,
  },
  root: {},
  labelIcon: {},
  textColorInherit: {},
  textColorPrimary: {},
  textColorSecondary: {},
  selected: {},
  disabled: {},
  wrapper: {},
});

export const Tab = React.forwardRef((
  props: ITabProps & WithStyles<typeof styles>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { classes, fullWidth, variant, ...other } = props;

  const rootClasses = classnames(
    {
      [classes.nonFullWidth]: !fullWidth,
      [classes.global]: variant === TabVariant.global,
      [classes.section]: variant === TabVariant.section,
    },
    classes.root,
  );

  return (
    <MuiTab
      classes={{
        root: rootClasses,
        labelIcon: classes.labelIcon,
        textColorInherit: classes.textColorInherit,
        textColorPrimary: classes.textColorPrimary,
        textColorSecondary: classes.textColorSecondary,
        selected: classes.selected,
        disabled: classes.disabled,
        fullWidth: classes.fullWidth,
        wrapper: classes.wrapper,
      }}
      fullWidth={fullWidth}
      ref={ref}
      {...other}
    />
  );
});

export default withStyles(styles)(Tab);
