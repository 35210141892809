import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ButtonOverrides = (theme: Theme): Overrides => ({
  MuiButton: {
    root: {
      border: '1px solid transparent',
      fontSize: theme.typography.button.fontSize,
      fontWeight: undefined,
      lineHeight: 1.4,
      minHeight: '38px',
      padding: '8px 12px',
      textTransform: undefined,
    },
    contained: {
      boxShadow: undefined,
      backgroundColor: undefined,
      color: undefined,
      '&:hover': {
        backgroundColor: undefined,
        borderColor: undefined,
      },
      '&:active': {
        boxShadow: undefined,
      },
      '&$focusVisible': {
        boxShadow: undefined,
      },
      '&$disabled': {
        boxShadow: undefined,
      },
    },
    containedSecondary: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.border.main,
        '@media (hover: none)': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          // TODO: verify @media (hover: none) active styles with design
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.text.secondary,
        },
      },
    },
    containedPrimary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
        '@media (hover: none)': {
          backgroundColor: theme.palette.primary.main,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          // TODO: verify @media (hover: none) active styles with design
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.b8,
          color: theme.palette.background.b2,
        },
      },
    },
    // The focus ring on outline buttons replaces the normal button border.
    // This is different from other button types, where the ring is
    // inset inside the button's area.
    outlined: {
      backgroundColor: 'transparent',
      border: undefined,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 1,
        left: 1,
        right: 1,
        bottom: 1,
        borderRadius: 'inherit',
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      },
      '&:hover, &:focus, &:active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&:disabled::before': {
        boxShadow: `0 0 0 2px ${theme.palette.border.main}`,
      },
      '&$disabled': {
        border: undefined,
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: undefined,
      },
    },
  },
});
