import * as React from 'react';
import { default as MuiStepConnector, StepConnectorProps } from '@material-ui/core/StepConnector';

export const StepConnector = React.forwardRef((
  props: StepConnectorProps,
  ref: React.Ref<unknown>,
) => (
  <MuiStepConnector ref={ref} {...props} />
));

export default StepConnector;
