import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { default as MuiCard, CardProps } from '@material-ui/core/Card';

export const useStyles = makeStyles(() => createStyles({
  raised: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
  },
}));

export const Card = React.forwardRef((
  props: CardProps,
  ref: React.Ref<unknown>,
) => {
  const { raised, className, ...other } = props;
  const classes = useStyles(props);

  return (
    <MuiCard
      ref={ref}
      elevation={0}
      className={classnames(
        className,
        {
          [classes.raised]: raised,
        },
      )}
      {...other}
    />
  );
});

export default Card;
