import * as React from 'react';
import { default as MuiStepper, StepperProps } from '@material-ui/core/Stepper';

export const Stepper = React.forwardRef((
  props: StepperProps,
  ref: React.Ref<unknown>,
) => (
  <MuiStepper ref={ref} {...props} />
));

export default Stepper;
