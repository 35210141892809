import * as React from 'react';
import { default as MuiMenuItem, MenuItemProps } from '@material-ui/core/MenuItem';

export const MenuItem = React.forwardRef((
  props: MenuItemProps,
  ref: React.Ref<HTMLLIElement>,
) => {
  const { button, ...other } = props;

  // button as any - https://github.com/mui-org/material-ui/issues/14971
  return <MuiMenuItem button={button as any} ref={ref} {...other} />;
});

export default MenuItem;
