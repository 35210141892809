import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const StepperOverrides = (theme: Theme): Overrides => ({
  MuiStepper: {
    root: {
      padding: '12px 0',
    },
  },
});
