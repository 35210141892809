import * as React from 'react';
import { default as MuiCardActions, CardActionsProps } from '@material-ui/core/CardActions';

export const CardActions = React.forwardRef((
  props: CardActionsProps,
  ref: React.Ref<unknown>,
) => (
  <MuiCardActions ref={ref} {...props} />
));

export default CardActions;
