import * as React from 'react';
import Tab from '../Tab';
import { IIconTabProps } from '../Tab.types';
import { Tooltip } from '../../Tooltip';

const IconTab = React.forwardRef((
  props: IIconTabProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { label, tooltipPlacement, ...other } = props;

  return (
    <Tooltip
      title={label as NonNullable<React.ReactNode>}
      placement={tooltipPlacement || 'bottom'}
    >
      <Tab
        {...other}
        ref={ref}
        // label={undefined}
      />
    </Tooltip>
  );
});

export default IconTab;
