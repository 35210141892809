import * as React from 'react';
import { default as MuiTableCell, TableCellProps } from '@material-ui/core/TableCell';

export const TableCell = React.forwardRef((
  props: TableCellProps,
  ref: React.Ref<unknown>,
) => (
  <MuiTableCell ref={ref} {...props} />
));

export default TableCell;
