import * as React from 'react';
import { default as MuiButton } from '@material-ui/core/Button';
import { IButtonProps } from './Button.types';

export const Button = React.forwardRef((
  props: IButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) => <MuiButton ref={ref} disableElevation {...props} />);

export default Button;
