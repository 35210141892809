import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba } from '../styles/utilities';

export const OutlinedInputOverrides = (theme: Theme): Overrides => {
  const disabledBackgroundColor = theme.palette.type === 'light' ? theme.palette.background.b3 : '#1b1b1b';

  return {
    MuiOutlinedInput: {
      root: {
        backgroundColor: theme.palette.background.default,
        '&:not($disabled):not($error) $notchedOutline': {
          borderColor: theme.palette.border.main,
        },
        '&$focused:not($error) $notchedOutline': {
          boxShadow: `inset 0 0 0 4px ${rgba(theme.palette.focus.main, 0.1)}`,
          borderColor: theme.palette.focus.main,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: undefined,
        },
        '& $notchedOutline': {
          borderColor: undefined,
        },
        '& $notchedOutline > legend': {
          // match default value from MUI and bypass rem conversion
          maxWidth: '0.01keep-px',
        },
        '&$focused $notchedOutline': {
          borderWidth: undefined,
          borderColor: undefined,
        },
        '&$disabled': {
          backgroundColor: disabledBackgroundColor,
          '& $notchedOutline': {
            borderColor: theme.palette.border.main,
          },
        },
        '&$adornedStart': {
          paddingLeft: undefined,
          '& > div': {
            color: theme.palette.text.secondary,
          },
        },
        '&$adornedEnd': {
          paddingRight: undefined,
          '& > div': {
            color: theme.palette.text.secondary,
          },
        },
        '&$error': {
          borderColor: theme.palette.error.main,
          '&$focused': {
            boxShadow: `inset 0 0 0 4px ${rgba(theme.palette.error.main, 0.1)}`,
          },
        },
      },
      input: {
        padding: undefined,
      },
      inputMultiline: {
        padding: undefined,
      },
    },
  };
};
