import * as React from 'react';
import classnames from 'classnames';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiDivider } from '@material-ui/core/Divider';
import { DividerProps } from './Divider.types';

export const styles = (theme: Theme) => {
  const iconSpacing = 24;

  return createStyles({
    insetSmall: {
      marginLeft: 16 + iconSpacing,
    },
    insetMedium: {
      marginLeft: 24 + iconSpacing,
    },
    insetLarge: {
      marginLeft: 38 + iconSpacing,
    },
    insetXLarge: {
      marginLeft: 42 + iconSpacing,
    },
  });
};

export const Divider = React.forwardRef((
  props: DividerProps & WithStyles<typeof styles>,
  ref: React.Ref<HTMLHRElement>,
) => {
  const { classes, className, insetSmall, insetMedium, insetLarge, insetXLarge, ...other } = props;
  const classNames = classnames(
    insetSmall && classes.insetSmall,
    insetMedium && classes.insetMedium,
    insetLarge && classes.insetLarge,
    insetXLarge && classes.insetXLarge,
    className,
  );

  return (
    <MuiDivider className={classNames} ref={ref} {...other} />
  );
});

export default withStyles(styles)(Divider);
