import * as React from 'react';
import { default as MuiInputBase, InputBaseProps } from '@material-ui/core/InputBase';

export const InputBase = React.forwardRef((
  props: InputBaseProps,
  ref: React.Ref<unknown>,
) => (
  <MuiInputBase ref={ref} {...props} />
));

export default InputBase;
