export function pxToRem(value: number): string {
  return `${(value / 16)}rem`;
}

export const fontFamily = '"Open Sans", "Helvetica", "Arial", sans-serif';
export const fontFamilySerif = '"Noto Serif", serif';

export const fontSizes = {
  fontSizeXSmall: pxToRem(10),
  fontSizeSmall: pxToRem(12),
  fontSizeDefault: pxToRem(14),
  fontSizeMedium: pxToRem(16),
  fontSizeLarge: pxToRem(18),
  fontSizeXLarge: pxToRem(24),
  fontSizeXXLarge: pxToRem(30),
};

export const fontWeights = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
};

export const typeStyles = {
  display1: {
    fontFamily: fontFamilySerif,
    fontSize: fontSizes.fontSizeXXLarge,
    fontWeight: fontWeights.fontWeightRegular,
    lineHeight: 1.5,
  },
  display2: {
    fontSize: fontSizes.fontSizeXXLarge,
    fontWeight: fontWeights.fontWeightLight,
    lineHeight: 1.5,
  },
  headingXLarge: {
    fontSize: fontSizes.fontSizeXLarge,
    fontWeight: fontWeights.fontWeightRegular,
    lineHeight: 1.5,
  },
  headingLarge: {
    fontSize: fontSizes.fontSizeLarge,
    fontWeight: fontWeights.fontWeightSemiBold,
    lineHeight: 1.5,
  },
  headingMedium: {
    fontSize: fontSizes.fontSizeMedium,
    fontWeight: fontWeights.fontWeightSemiBold,
    lineHeight: 1.5,
  },
  headingDefault: {
    fontSize: fontSizes.fontSizeDefault,
    fontWeight: fontWeights.fontWeightSemiBold,
    lineHeight: 1.5,
  },
  headingSmall: {
    fontSize: fontSizes.fontSizeSmall,
    fontWeight: fontWeights.fontWeightSemiBold,
    lineHeight: 1.5,
  },
  headingXSmall: {
    fontSize: fontSizes.fontSizeXSmall,
    fontWeight: fontWeights.fontWeightBold,
    lineHeight: 1.5,
  },
  bodyDefault: {
    fontFamily,
    fontSize: fontSizes.fontSizeDefault,
    fontWeight: fontWeights.fontWeightRegular,
    lineHeight: 1.5,
  },
  bodySmall: {
    fontFamily,
    fontSize: fontSizes.fontSizeSmall,
    fontWeight: fontWeights.fontWeightRegular,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: fontSizes.fontSizeMedium,
  },
  subtitle2: {
    fontSize: fontSizes.fontSizeDefault,
    fontWeight: fontWeights.fontWeightSemiBold,
  },
  button: {
    fontSize: fontSizes.fontSizeDefault,
    fontWeight: fontWeights.fontWeightRegular,
  },
  caption: {
    fontSize: fontSizes.fontSizeSmall,
  },
  overline: {
    fontSize: fontSizes.fontSizeSmall,
  },
}

export const typography = {
  pxToRem,
  fontFamily,
  fontFamilySerif,
  ...fontSizes,
  ...fontWeights,
  ...typeStyles,
}

export default typography;