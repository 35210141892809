import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipProps } from './Tooltip.types';

export const BbTooltip: React.SFC<TooltipProps> = (props) => {
  const { disableArrow, PopperProps, ...other } = props;

  return (
    <Tooltip
      arrow={!disableArrow}
      PopperProps={{
        role: 'tooltip',
        ...PopperProps,
      }}
      {...other}
    />
  );
};

export default BbTooltip;
