import * as React from 'react';
import { default as MuiTableFooter, TableFooterProps } from '@material-ui/core/TableFooter';

export const TableFooter = React.forwardRef((
  props: TableFooterProps,
  ref: React.Ref<HTMLTableSectionElement>,
) => (
  <MuiTableFooter ref={ref} {...props} />
));

export default TableFooter;
