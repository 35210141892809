import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '../styles';
import { IBbThemeProviderProps } from './BbThemeProvider.types';

export const BbThemeProvider: React.SFC<IBbThemeProviderProps> = (props) => {
  const { theme, isRtl, children } = props;

  const bbTheme = createTheme({
    direction: isRtl ? 'rtl' : 'ltr',
    palette: {
      type: theme === 'dark' ? 'dark' : 'light',
    },
  });

  return (
    <MuiThemeProvider theme={bbTheme}>
      {children}
    </MuiThemeProvider>
  );
};
