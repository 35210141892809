import { TabProps } from '@material-ui/core/Tab';
import { TooltipProps } from '@material-ui/core/Tooltip';

export enum TabVariant {
  global = 'global',
  section = 'section',
}

export interface ITabProps extends TabProps {
  variant?: TabVariant;
}

export interface IIconTabProps extends ITabProps {
  tooltipPlacement?: TooltipProps['placement'];
}
