import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

// There's an additional style rule in the the ListItemText component definition
// that applies a heavier font weight to primary children when a secondary child
// isn't present.

export const ListItemTextOverrides = (theme: Theme): Overrides => ({
  MuiListItemText: {
    root: {
      padding: '0 12px',
      marginTop: undefined,
      marginBottom: undefined,
    },
    secondary: {
      fontSize: theme.typography.fontSizeDefault,
    },
    multiline: {
      marginTop: undefined,
      marginBottom: undefined,
    },
  },
});
