import * as React from 'react';
import { create, Jss } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import jssRtl from 'jss-rtl';
import { BbThemeProvider } from '../BbThemeProvider';
import pxToRem from '../../internal/utilities/px-to-rem';

export interface JssThemeProviderProps {
  jssInsertionPoint?: string | HTMLElement;
  theme?: string;
  isRtl?: boolean;
  children: React.ReactNode;
  generateClassName?: ReturnType<typeof createGenerateClassName>;
}

export const JssThemeProvider: React.FunctionComponent<JssThemeProviderProps> = (props) => {
  const { theme, jssInsertionPoint, isRtl, generateClassName } = props;
  const jss: Jss = create({
    plugins: [...jssPreset().plugins, pxToRem(), jssRtl()],
    insertionPoint: jssInsertionPoint,
  });

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <BbThemeProvider theme={theme} isRtl={isRtl}>
        <CssBaseline />
        {props.children}
      </BbThemeProvider>
    </StylesProvider>
  );
};

export default JssThemeProvider;
