import * as React from 'react';
import { default as MuiFormControlLabel, FormControlLabelProps } from '@material-ui/core/FormControlLabel';

export const FormControlLabel = React.forwardRef((
  props: FormControlLabelProps,
  ref: React.Ref<unknown>,
) => (
  <MuiFormControlLabel ref={ref} {...props} />
));

export default FormControlLabel;
