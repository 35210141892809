import * as React from 'react';
import {
  default as MuiCheckbox,
  CheckboxProps,
} from '@material-ui/core/Checkbox';
import { CheckboxIcon } from './CheckboxIcon';

export const Checkbox = React.forwardRef(
  (props: CheckboxProps, ref: React.Ref<HTMLButtonElement>) => {
    const { disabled, ...other } = props;

    return (
      <MuiCheckbox
        checkedIcon={<CheckboxIcon checked={true} disabled={disabled} />}
        icon={<CheckboxIcon disabled={disabled} />}
        indeterminateIcon={
          <CheckboxIcon indeterminate={true} disabled={disabled} />
        }
        disabled={disabled}
        ref={ref}
        {...other}
      />
    );
  },
);

export default Checkbox;
