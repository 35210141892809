import * as React from 'react';
import { default as MuiListItemIcon, ListItemIconProps } from '@material-ui/core/ListItemIcon';

export const ListItemIcon = React.forwardRef((
  props: ListItemIconProps,
  ref: React.Ref<unknown>,
) => (
  <MuiListItemIcon ref={ref} {...props} />
));

export default ListItemIcon;
