import * as React from 'react';
import { default as MuiTable, TableProps } from '@material-ui/core/Table';

export const Table = React.forwardRef((
  props: TableProps,
  ref: React.Ref<HTMLTableElement>,
) => (
  <MuiTable ref={ref} {...props} />
));

export default Table;
