import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Palette } from '@material-ui/core/styles/createPalette';
import { bbIconPalette } from '@bb-ui/styling/dist';

export const SvgIconOverrides = (theme: Theme, palette: Palette): Overrides => ({
  MuiSvgIcon: {
    colorPrimary: {
      '--icon-fill-color': theme.palette.type === 'light' ? bbIconPalette.primaryFill : 'none',
    },
    colorError: {
      color: bbIconPalette.errorStroke,
      '--icon-fill-color': theme.palette.type === 'light' ? palette.error.light : 'none',
    },
    colorDisabled: {
      '--icon-fill-color': theme.palette.type === 'light' ? palette.action.disabledBackground : 'none',
    },
    fontSizeSmall: {
      fontSize: 16,
    },
    fontSizeLarge: {
      fontSize: 38,
    },
  },
});
