import * as React from 'react';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { ITextFieldProps } from './TextField.types';
import { defaultMenuPlacement } from '../Menu/Menu';

export const TextField = React.forwardRef<HTMLDivElement, ITextFieldProps>((
  props,
  ref,
) => {
  // We pull out variant but don't use it because we will be overriding it below
  // and don't want it to be spread.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, floatingLabel, InputProps: ClientInputProps, onChange, onKeyDown, onKeyUp, onBlur, onFocus, variant, ...other } = props;

  // MUI passes the following props to InputProps.
  // We destructure above to prevent passing into `...other`, then include them along with any client provided InputProps

  const InputProps = {
    onChange,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    ...ClientInputProps,
  };

  if (floatingLabel) {
    return (
      <MuiTextField
        error={error}
        variant="outlined"
        InputProps={InputProps}
        SelectProps={{
          MenuProps: defaultMenuPlacement,
        }}
        FormHelperTextProps={{
          role: error ? 'alert' : undefined,
        }}
        ref={ref}
        {...other}
      />
    );
  }

  return (
    <MuiTextField
      error={error}
      variant="standard"
      InputProps={{ disableUnderline: true, ...InputProps }}
      SelectProps={{
        MenuProps: defaultMenuPlacement,
      }}
      FormHelperTextProps={{
        role: error ? 'alert' : undefined,
      }}
      ref={ref}
      {...other}
    />
  );
});

export default TextField;
