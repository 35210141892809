import { CSSProperties } from '@material-ui/core/styles/withStyles';
// FIXME in PLAT-1384: circular dependency with other files
// eslint-disable-next-line import/no-cycle
import { getColor, ThemeType } from './themeHelpers';

export interface FocusOutlineOptions {
  position?: 'inset' | number;
  colorInside?: string;
  colorOutside?: string;
}

export const focusOutline = (theme: ThemeType, options?: FocusOutlineOptions): CSSProperties => {
  const colorIn = options && options.colorInside || getColor('focus.light', theme);
  const colorOut = options && options.colorOutside || getColor('focus.main', theme);
  let offset = -1;

  if (options && typeof options.position === 'number') {
    offset = options.position;
  } else if (options && options.position === 'inset') {
    offset = 1;
  }

  return {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: offset,
      left: offset,
      right: offset,
      bottom: offset,
      borderRadius: 'inherit',
      boxShadow: `0 0 0 1px ${colorIn}, 0 0 0 2px ${colorOut}, 0 0 0 3px #fff`,
      // needed for high contrast mode
      border: '2px solid transparent',
    },
  };
};
