import * as React from 'react';
import classnames from 'classnames';
import { default as MuiDialogContent, DialogContentProps } from '@material-ui/core/DialogContent';

export const DialogContent = React.forwardRef((
  props: DialogContentProps,
  ref: React.Ref<unknown>,
) => {
  const { className, ...other } = props;
  const combinedClassName = classnames(className, 'bb-dialog-content');

  return <MuiDialogContent tabIndex={-1} className={combinedClassName} ref={ref} {...other} />;
});

export default DialogContent;
