import * as React from 'react';
import { default as MuiListSubheader, ListSubheaderProps } from '@material-ui/core/ListSubheader';

export const ListSubheader = React.forwardRef((
  props: ListSubheaderProps,
  ref: React.Ref<HTMLLIElement>,
) => (
  <MuiListSubheader ref={ref} component="li" {...props} />
));

export default ListSubheader;
