import * as React from 'react';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import FocusTrap from 'focus-trap-react';
import { DialogProps } from './Dialog.types';

const DialogFocus = React.forwardRef((props: DialogProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, hasTitle } = props;

  return (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: hasTitle ? '.bb-h1-element' : '.bb-dialog-content',
        fallbackFocus: '.bb-dialog-content',
      }}
    >
      <div ref={ref}>
        {children}
      </div>
    </FocusTrap>
  );
});

export const Dialog = React.forwardRef((
  props: DialogProps,
  ref: React.Ref<unknown>,
) => {
  const { children, hasTitle, open, ...other } = props;

  return (
    <>
      <MuiDialog
        disableAutoFocus
        disableEnforceFocus
        maxWidth="md"
        scroll="body"
        PaperProps={{
          elevation: 0,
        }}
        TransitionProps={{
          role: undefined,
        } as any}
        open={open}
        ref={ref}
        {...other}
      >
        <DialogFocus
          open={open}
          hasTitle={hasTitle}
        >
          {children}
        </DialogFocus>
      </MuiDialog>
    </>
  );
});

DialogFocus.defaultProps = {
  hasTitle: true,
};

export default Dialog;
