import * as React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { StepIconProps as MuiStepIconProps } from '@material-ui/core/StepIcon';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Attention } from '../../../internal/icons/small/Attention';
import { Circle } from '../../../internal/icons/small/Circle';
import { CircleFilled } from '../../../internal/icons/small/CircleFilled';
import { Complete } from '../../../internal/icons/small/Complete';
import { getColor } from '../../styles';

export interface StepIconProps extends Partial<MuiStepIconProps> {
  activeLabel?: string;
  completedLabel?: string;
  errorLabel?: string;
  iconId: string;
  incompleteLabel?: string;
}

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    color: getColor('secondary.main', theme),
  },
  active: {
    color: getColor('brand.main', theme),
  },
  completed: {
    color: getColor('brand.main', theme),
  },
  error: {
    color: getColor('error.main', theme),
  },
});

const iconType = (props: StepIconProps) => {
  const {
    active,
    activeLabel,
    completed,
    completedLabel,
    error,
    errorLabel,
    iconId,
    incompleteLabel,
  } = props;
  if (error) {
    return <Attention id={iconId} className="js-StepIcon-error" aria-hidden={false} role="img" aria-label={errorLabel} />;
  }
  if (active) {
    return <CircleFilled id={iconId} className="js-StepIcon-active" aria-hidden={false} role="img" aria-label={activeLabel} />;
  }
  if (completed) {
    return <Complete id={iconId} className="js-StepIcon-completed" aria-hidden={false} role="img" aria-label={completedLabel} />;
  }
  return <Circle id={iconId} className="js-StepIcon-unedited" aria-hidden={false} role="img" aria-label={incompleteLabel} />;
};

export const StepIcon = React.forwardRef((
  props: StepIconProps & WithStyles<typeof styles>,
  ref: React.Ref<unknown>,
) => {
  const { classes, active, completed, error } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.error]: error,
      })}
    >
      {iconType(props)}
    </div>
  );
});

export default withStyles(styles)(StepIcon);
