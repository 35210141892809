import * as React from 'react';
import classnames from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { default as MuiStepContent, StepContentProps } from '@material-ui/core/StepContent';

export interface IStepContentProps extends StepContentProps {
  active?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  active: {
    marginBottom: '6px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
}));

export const StepContent = React.forwardRef((
  props: IStepContentProps,
  ref: React.Ref<unknown>,
) => {
  const { active } = props;
  const classes = useStyles(props);

  return (
    <MuiStepContent
      ref={ref}
      className={classnames({
        [classes.active]: active,
      })}
      {...props}
    />
  );
});

export default StepContent;
