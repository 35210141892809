import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const CardOverrides = (theme: Theme): Overrides => ({
  MuiCard: {
    root: {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
  MuiCardContent: {
    root: {
      '&:last-child': {
        paddingBottom: undefined,
      },
    },
  },
  MuiCardHeader: {
    action: {
      marginBottom: theme.spacing(-1),
    },
  },
  MuiCardActionArea: {
    root: {
      '&:hover': {
        '& $focusHighlight': {
          opacity: 0.12,
        },
      },
      '&$focusVisible': {
        '& $focusHighlight': {
          opacity: 0,
        },
      },
    },
  },
});
