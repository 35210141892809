import * as React from 'react';
import { default as MuiInput, InputProps } from '@material-ui/core/Input';

export const Input = React.forwardRef((
  props: InputProps,
  ref: React.Ref<unknown>,
) => (
  <MuiInput disableUnderline={true} ref={ref} {...props} />
));

export default Input;
