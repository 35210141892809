import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const TabOverrides = (theme: Theme): Overrides => {
  const focusOffset = '2px !important';

  return {
    MuiTab: {
      root: {
        fontWeight: theme.typography.fontWeightSemiBold,
        // Need this for focus ring to be visible
        '&::before': {
          top: focusOffset,
          left: focusOffset,
          right: focusOffset,
          bottom: focusOffset,
          // Make the focus ring show up on top of the active box shadow on the bottom of the button
          zIndex: 1,
        },
        '&$selected': {
          color: theme.palette.text.primary,
        },
        '&:focus': {
          color: theme.palette.text.primary,
        },
        '&:hover': {
          boxShadow: `inset 0 -4px ${theme.palette.border.main}`,
          color: theme.palette.text.primary,
        },
        // MUI uses a media query to change the font size for wider screens
        // We want to use the same font size for all screens
        '@media (min-width: 0px)': {
          // Use default font size as default
          fontSize: theme.typography.fontSizeDefault,
          // Minimum width of tabs set to 60px
          minWidth: '60px',
        },
      },
    },
  };
};
