import { default as i18next, i18n as I18next, InitOptions } from 'i18next';
import ICU from 'i18next-icu';
import HttpApi, { RequestCallback } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import merge from 'lodash.merge';

export interface IInitOptions extends InitOptions {
  backend?: Record<string, any>;
}

export class I18n {
  i18n: I18next;

  defaultOptions: InitOptions = {
    debug: process.env.NODE_ENV === 'development', // logs info level to console output
    nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    fallbackLng: 'en', // language to use if translations in user language are not available
    ns: ['translation'], // default namespace
    whitelist: ['en'], // supported languages
    detection: {
      lookupQuerystring: 'lng', // default lookupQuerystring
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      // points to CRA's default public folder: e.g. /locales/en/translation.json
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      request: this.handleRequest.bind(this),
    },
  };

  private requiredPlugins: { [key: string]: any } = {
    // use ICU format
    // learn more: https://github.com/i18next/i18next-icu
    icu: new ICU(),
  }

  plugins: { [key: string]: any } = {
    // load translations using HttpApi
    // learn more: https://github.com/i18next/i18next-http-backend
    httpApi: new HttpApi(),
    // pass the i18n instance to react-i18next
    // learn more: https://react.i18next.com/
    initReactI18next,
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    languageDetector: new LanguageDetector(),
  };

  constructor(consumerI18n?: I18next) {
    this.i18n = consumerI18n || i18next;
  }

  get instance() {
    return this.i18n;
  }

  static getQueryParamValue(urlString: string, queryParam: string): string | null {
    let url: URL;

    try {
      url = new URL(urlString);
    } catch (err) {
      url = new URL(`http://${urlString}`);
    }

    return new URLSearchParams(url.search.substring(1)).get(queryParam);
  }

  // Note: not settings as static to allow consumer override, lng needed for consumer
  // eslint-disable-next-line class-methods-use-this
  async getTranslations({ url }: { url: string; lng: string }) {
    const translations = await axios(url);
    return JSON.stringify(translations.data);
  }

  // Note: not settings as static to allow consumer override
  // eslint-disable-next-line class-methods-use-this
  async getLocaleData(lng: string) {
    const localeData = await import(`i18next-icu/locale-data/${lng.split('-')[0]}`);
    return localeData.default;
  }

  async handleRequest(options: InitOptions, url: string, payload: {} | string, callback: RequestCallback) {
    const lng = I18n.getQueryParamValue(url, 'lng');
    const whitelist = this.i18n.options.whitelist || options.whitelist;

    if (!lng) {
      return callback('Invalid loadPath: end of path must include "?lng={{lng}}"', { data: '', status: 0 });
    }

    try {
      /*
      * If the locale isn't whitelisted, throw to prevent fetch
      * If the locale includes a postcode that isn't whitelisted, it will fallback to the base language code
      */
      if (whitelist && !whitelist.includes(lng)) {
        throw new Error(`"${lng}" not found in the whitelist. Reattempting with fallback.`);
      }

      const [translations, localeData] = await Promise.all([
        this.getTranslations({ url, lng }),
        this.getLocaleData(lng),
      ]);

      // init ICU with locale data
      this.requiredPlugins.icu.init(this.i18n, localeData);

      // pass translation response as string
      return callback(null, {
        data: translations,
        status: 200,
      });
    } catch (err) {
      return callback(null, { data: '', status: err });
    }
  }

  init(options?: IInitOptions): I18next {
    const combinedPlugins = { ...this.requiredPlugins, ...this.plugins };
    const combinedOptions = merge(this.defaultOptions, options);

    // use plugins
    Object.entries(combinedPlugins).forEach(([key, plugin]) => {
      // httpApi plugin and options shouldn't be used when resources are provided
      if (key === 'httpApi' && combinedOptions && combinedOptions!.resources) {
        combinedOptions.backend = undefined;
        return;
      }

      this.i18n.use(plugin);
    });

    // append lng query param unless provided with a custom backend request
    // TODO: simplify condition with optional chaining after updating to typescript >= 3.7
    if (combinedOptions.backend && combinedOptions.backend.loadPath && !(options && options.backend && options.backend.request)) {
      combinedOptions.backend.loadPath = `${combinedOptions.backend.loadPath}?lng={{lng}}`;
    }

    // initialize with combined options
    this.i18n.init(combinedOptions);

    // return initialized instance
    return this.i18n;
  }
}

export default new I18n();
