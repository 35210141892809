import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const StepContentOverrides = (theme: Theme): Overrides => ({
  MuiStepContent: {
    root: {
      marginTop: '5px',
      '& button + button': {
        marginLeft: '2px',
      },
      marginLeft: 0,
      borderLeft: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: '7px',
        borderLeft: `2px solid ${theme.palette.secondary.main}`,
        paddingLeft: '15px',
      },
    },
    last: {
      borderLeftColor: 'transparent',
    },
  },
});
