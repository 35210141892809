import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const AvatarOverrides = (theme: Theme): Overrides => ({
  MuiAvatar: {
    colorDefault: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    // See also useMuiStyles() in Avatar.tsx.
    root: {
      backgroundColor: theme.palette.primary.main,
      bottom: theme.spacing(0.5),
      color: theme.palette.primary.contrastText,
      height: undefined,
      left: theme.spacing(0.5),
      position: 'absolute',
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      width: undefined,
    },
  },
});
