import * as React from 'react';
import { default as MuiListItemSecondaryAction, ListItemSecondaryActionProps } from '@material-ui/core/ListItemSecondaryAction';

export const ListItemSecondaryAction = React.forwardRef((
  props: ListItemSecondaryActionProps,
  ref: React.Ref<unknown>,
) => (
  <MuiListItemSecondaryAction {...props} ref={ref} />
));

export default ListItemSecondaryAction;
