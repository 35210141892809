import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiListItemText, ListItemTextProps } from '@material-ui/core/ListItemText';
import { TypographyProps } from '@material-ui/core/Typography';

// This is a style instead of an override so that we can apply based on props,
// e.g. we only increase the weight of the primary text if a secondary line is
// also present.

export const useStyles = makeStyles((theme: Theme) => createStyles({
  primary: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
}));

export const ListItemText = React.forwardRef((
  props: ListItemTextProps,
  ref: React.Ref<unknown>,
) => {
  const { secondary, ...other } = props;
  const classes = useStyles(props);

  // Give the primary text a stable class name so that ListItem styling can
  // target it. We can't style here because the effect we're trying to
  // accomplish is, if this component is inside a button (set via a <ListItem>
  // prop), underline *only* the primary child when we hover over the
  // <ListItem>. We can't even apply a hover style here because <ListItem> puts
  // padding around this component.

  const primaryTypographyProps: TypographyProps = {
    className: 'primary-text',
    classes: { root: secondary ? classes.primary : undefined },
  };

  return (
    <MuiListItemText
      primaryTypographyProps={primaryTypographyProps}
      secondary={secondary || undefined}
      ref={ref}
      {...other}
    />
  );
});

export default ListItemText;
