import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  containerAnchorOriginTopCenter: {
    [theme.breakpoints.only('sm')]: {
      left: 0,
      right: 0,
      width: '100%',
    },
  },
  snackbarContentRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '815px',
      width: '70vw',
    },
  },
}));

export default useStyles;
