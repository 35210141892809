import * as React from 'react';
import { SnackbarProvider as BaseSnackbarProvider, SnackbarProviderProps } from 'notistack';
import { useStyles } from './SnackbarProvider.styles';

export { SnackbarProviderProps } from 'notistack';

export const defaultProps: SnackbarProviderProps = {
  maxSnack: 2,
  hideIconVariant: true,
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

/**
 * Bb customized SnackbarProvider from the 3rd-party library that allows for stackable snackbars.
 * https://iamhosseindhv.com/notistack
 */
export const SnackbarProvider = React.forwardRef(
  (props: SnackbarProviderProps, ref: React.Ref<unknown>) => {
    // Merge the default props from notistack with the default overrides in this component and then with
    // any overrides the component consumer provides.
    const mergedProps = { ...BaseSnackbarProvider.defaultProps, ...defaultProps, ...props };
    const { snackbarContentRoot, ...providerClasses } = useStyles(mergedProps);
    // Get the props without the classes property as we will pass that in separately with custom style
    // classes applied using useStyles hook.
    const { classes, ...otherProviderProps } = mergedProps;
    return (
      <BaseSnackbarProvider
        classes={providerClasses}
        ContentProps={{ className: snackbarContentRoot }}
        ref={ref}
        {...otherProviderProps}
      />
    );
  },
);

export default SnackbarProvider;
