import * as React from 'react';
import { default as MuiList, ListProps } from '@material-ui/core/List';

export const List = React.forwardRef((
  props: ListProps,
  ref: React.Ref<HTMLUListElement>,
) => (
  <MuiList ref={ref} {...props} />
));

export default List;
