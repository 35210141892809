import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const LinearProgressOverrides = (theme: Theme): Overrides => {
  const progressBackground = theme.palette.type === 'light' ? theme.palette.brand.light : theme.palette.brand.dark;
  return {
    MuiLinearProgress: {
      root: {
        height: '6px',
        borderRadius: '6px',
      },
      bar: {
        borderRadius: '6px',
      },
      colorPrimary: {
        backgroundColor: progressBackground,
      },
      barColorPrimary: {
        backgroundColor: theme.palette.brand.main,
      },
    },
  };
};
