import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const TabsOverrides = (theme: Theme): Overrides => ({
  MuiTabs: {
    indicator: {
      height: '4px',
      backgroundColor: theme.palette.brand.main,
    },
    scrollButtons: {
      '& svg': {
        fontSize: '24px',
      },
    },
  },
});
