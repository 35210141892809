import * as React from 'react';
import { default as MuiCardMedia, CardMediaProps } from '@material-ui/core/CardMedia';

export const CardMedia = React.forwardRef((
  props: CardMediaProps,
  ref: React.Ref<HTMLDivElement>,
) => (
  <MuiCardMedia ref={ref} {...props} />
));

export default CardMedia;
