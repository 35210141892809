import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ChipOverrides = (theme: Theme): Overrides => ({
  MuiChip: {
    root: {
      justifyContent: 'flex-start',
      height: undefined,
      padding: '4px 0',
      whiteSpace: undefined,
      backgroundColor: theme.palette.background.b4,
      border: '1px solid transparent',
      color: theme.palette.primary.main,
    },
    clickable: {
      '&:hover': {
        backgroundColor: theme.palette.background.b3,
      },
    },
    deletable: {
      '&:focus': {
        backgroundColor: undefined,
      },
    },
    outlined: {
      borderColor: theme.palette.background.b8,
      '$deletable&:focus': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      marginLeft: 8,
      marginRight: -2, // accomodating left padding of label
      width: 16,
      height: 16,
    },
    label: {
      marginRight: 'auto',
      paddingLeft: '10px',
      paddingRight: '10px',
      whiteSpace: undefined,
      width: '100%',
    },
  },
});
