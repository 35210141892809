import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Palette } from '@material-ui/core/styles/createPalette';
import { rgba } from '../styles/utilities';

export const InputOverrides = (theme: Theme, palette: Palette): Overrides => {
  const disabledBackgroundColor = theme.palette.type === 'light' ? theme.palette.background.b3 : theme.palette.background.b8;

  return {
    MuiInput: {
      root: {
        backgroundColor: palette.background.b1,
        border: `1px solid ${palette.border.main}`,
        borderRadius: theme.shape.borderRadius,
        color: palette.text.primary,
        '&$focused': {
          borderColor: theme.palette.focus.main,
          boxShadow: `inset 0 0 0 4px ${rgba(theme.palette.focus.main, 0.1)}`,
        },
        '&$disabled': {
          backgroundColor: disabledBackgroundColor,
        },
        '&$error': {
          borderColor: theme.palette.error.main,
          '&$focused': {
            borderColor: theme.palette.error.main,
            boxShadow: `inset 0 0 0 4px ${rgba(theme.palette.error.main, 0.1)}`,
          },
        },
      },
      input: {
        '&[type="search"]::-webkit-search-cancel-button': {
          appearance: 'none',
        },
        '&[type="search"]::-ms-clear': {
          display: 'none',
          width: 0,
          height: 0,
        },
      },
      formControl: {
        'label + &': {
          marginTop: undefined,
        },
      },
    },
  };
};
