import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ExpansionPanelSummaryOverrides = (theme: Theme): Overrides => ({
  MuiExpansionPanelSummary: {
    // Vertically center items by default.
    content: {
      alignItems: 'center',
    },
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      '&$focused': {
        backgroundColor: undefined,
      },
    },
  },
});
