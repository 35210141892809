import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiExpansionPanel } from '@material-ui/core/ExpansionPanel';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ExpansionPanelProps } from './ExpansionPanel.types';

export const useStyles = makeStyles((theme: Theme) => ({
  root(props: ExpansionPanelProps) {
    const { border } = props;
    const result: CreateCSSProperties<ExpansionPanelProps> = {
      // Hide the border set by <Paper elevation={0}> below
      '&::before': {
        display: 'none',
      },
    };

    switch (border) {
      case 'thick':
        result.borderBottom = `2px solid ${theme.palette.background.b9}`;
        break;

      case 'thin':
        result.borderBottom = `1px solid ${theme.palette.background.b5}`;
        break;
    }

    return result;
  },
}));

export const ExpansionPanel: React.FunctionComponent<
ExpansionPanelProps
> = (props) => {
  const classes = useStyles(props);
  const { children, ...otherProps } = props;

  return (
    <MuiExpansionPanel elevation={0} classes={classes} {...otherProps}>
      {children}
    </MuiExpansionPanel>
  );
};

export default ExpansionPanel;
