import * as React from 'react';
import { default as MuiRadio, RadioProps } from '@material-ui/core/Radio';
import { RadioIcon } from './RadioIcon';

export const Radio = React.forwardRef(
  (props: RadioProps, ref: React.Ref<HTMLButtonElement>) => {
    const { disabled, ...other } = props;

    return (
      <MuiRadio
        aria-disabled={undefined}
        icon={<RadioIcon checked={false} disabled={disabled} />}
        checkedIcon={<RadioIcon checked={true} disabled={disabled} />}
        disabled={disabled}
        ref={ref}
        {...other}
      />
    );
  },
);

export default Radio;
