import * as React from 'react';
import { default as MuiTableSortLabel, TableSortLabelProps } from '@material-ui/core/TableSortLabel';
import { SortableArrows } from '../../internal/icons/small/SortableArrows';
import { SortableArrowUp } from '../../internal/icons/small/SortableArrowUp';

const ActiveIcon = (props: any) => <SortableArrowUp data-testid="active" fontSize="small" {...props} />;
const DefaultIcon = (props: any) => <SortableArrows data-testid="default" fontSize="small" {...props} />;

export const TableSortLabel = React.forwardRef((
  props: TableSortLabelProps,
  ref: React.Ref<HTMLSpanElement>,
) => {
  const { active } = props;

  return (
    <MuiTableSortLabel
      IconComponent={active ? ActiveIcon : DefaultIcon}
      ref={ref}
      {...props}
    />
  );
});

export default TableSortLabel;
