import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const InputAdornmentOverrides = (theme: Theme): Overrides => ({
  MuiInputAdornment: {
    positionStart: {
      marginTop: '2px',
      marginRight: '12px',
    },
    positionEnd: {
      marginTop: '2px',
      marginLeft: '12px',
      // Button should be centered so outline looks correct when focused
      '& > button': {
        marginTop: '-2px',
      },
    },
  },
});
