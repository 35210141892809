import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ExpansionPanelDetailsOverrides = (theme: Theme): Overrides => ({
  MuiExpansionPanelDetails: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});
