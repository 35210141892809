import * as React from 'react';
import { default as MuiDrawer, DrawerProps } from '@material-ui/core/Drawer';

export const Drawer = React.forwardRef((
  props: DrawerProps,
  ref?: React.Ref<unknown>,
) => (
  <MuiDrawer ref={ref} {...props} />
));

export default Drawer;
